import React from 'react';
import { Typography } from '@mui/material';

function Dashboard() {
    return (
        <div>
            <Typography variant="h4">仪表盘内容</Typography>
            <Typography variant="body1">这里是仪表盘的详细内容。</Typography>
        </div>
    );
}

export default Dashboard;
