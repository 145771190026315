import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function WarningGoForum () {
    const [open, setOpen] = useState(true); // 初始化为 true 直接打开对话框

    const handleClose = () => {
        setOpen(false);
        window.location.href = "/"

    };

    const handleClick = (event) => {
        setOpen(false);
        window.location.href = "https://teamside.dev"
    }


    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    隐私提醒
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        你即将前往外部论坛，你注意你的Pdweb账号安全。
                    </Typography>
                    <Typography gutterBottom>
                        You are about to go to the forum, please pay attention to the security of your Pdweb account.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        取消
                    </Button>
                    <Button autoFocus onClick={handleClick}>
                        知道了
                    </Button>
                </DialogActions>

            </BootstrapDialog>

    </React.Fragment>
);
}
